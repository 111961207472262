import { colouredBar, drawdownRiskTable } from "../../lib/utilities";

const LineGraph = ({ data }) => {
  const drawdown =
    data["cumulated_returns"]["all"][
      data["cumulated_returns"]["all"].length - 1
    ]["drawdown_risk"];

  return (
    <div className="block-module bar-graph">
      <h2>Drawdown Risk</h2>
      {/* todo: manca dato il api */}
      <p
        className="signal"
        style={{ color: drawdownRiskTable(drawdown, "color") }}
      >
        {drawdownRiskTable(drawdown, "label")}
      </p>
      <ul className="bar">
        <li style={{ backgroundColor: colouredBar(drawdown, 1) }}>01</li>
        <li style={{ backgroundColor: colouredBar(drawdown, 2) }}>02</li>
        <li style={{ backgroundColor: colouredBar(drawdown, 3) }}>03</li>
        <li style={{ backgroundColor: colouredBar(drawdown, 4) }}>04</li>
        <li style={{ backgroundColor: colouredBar(drawdown, 5) }}>05</li>
      </ul>
      <ul className="legend">
        <li>
          <span>01</span> extreme risk - inactive
        </li>
        <li>
          <span>02</span> very high risk
        </li>
        <li>
          <span>03</span> high risk
        </li>
        <li>
          <span>04</span> low risk
        </li>
        <li>
          <span>05</span> very low risk
        </li>
      </ul>
    </div>
  );
};

export default LineGraph;
