import { Link } from "react-router-dom";
import { formatPercentage } from "../../lib/utilities";

const SectorsList = ({ strategyCode, sectors }) => {
  return (
    <section className="sector-list">
      <ul>
        <li>
          <div className="title">
            <h2>Sector</h2>
          </div>
          <div className="label">Active stocks</div>
        </li>
        <li>
          <div className=""></div>
          <div className="label">Active stocks</div>
        </li>
        {sectors
          .filter((e) => e["sector"])
          .map((value, i) => (
            <li key={i}>
              <div className="sector">
                <Link
                  reloadDocument
                  to={`/strategy/${strategyCode}/${value["sector"]}`}
                >
                  {value["sector"]}
                </Link>
              </div>
              <div className="result">
                {formatPercentage(value["active_stocks"])}
                <span>
                  {value["active_stocks_count"]}/{value["total_stocks_count"]}
                </span>
              </div>
            </li>
          ))}
      </ul>
    </section>
  );
};

export default SectorsList;
